<template>
    <div class="row">
        <div class="col-12 mt-1">
            <div class="d-flex mt-0 mb-1" style="width:100%">
                <!-- MatrixSize -->
                <div class="dropdown me-2">
                    <button 
                        class="btn btn-sm btn-outline-primary dropdown-toggle" 
                        type="button" 
                        id="matrixSizeDD" 
                        data-bs-toggle="dropdown" 
                        aria-haspopup="true" 
                        aria-expanded="false" 
                        style="cursor: pointer;"
                        :disabled="props.TooManyIDs"
                    >
                        <i class="bi bi-arrows-fullscreen me-1" aria-hidden="true"></i> {{ $t('Matrix Size') }}
                    </button>
                    <div class="dropdown-menu" aria-labelledby="matrixSizeDD">
                        <button 
                            class="dropdown-item matrix-size" 
                            msize="small" 
                            @click="(e: Event) => { emitAndSetDropdownElementActive(e); }"
                        >{{ $t('Small') }}</button>

                        <button 
                            class="dropdown-item matrix-size active" 
                            msize="medium" 
                            @click="(e: Event) => { emitAndSetDropdownElementActive(e); }"
                        >{{ $t('Medium') }}</button>

                        <button 
                            class="dropdown-item matrix-size" 
                            msize="large" 
                            @click="(e: Event) => { emitAndSetDropdownElementActive(e); }"
                        >{{ $t('Large') }}</button>
                    </div>
                </div>

                <!-- Options -->
                <div class="dropdown">
                    <button 
                        class="btn btn-sm btn-outline-primary dropdown-toggle" 
                        type="button" 
                        data-bs-auto-close="outside" 
                        data-bs-toggle="dropdown" 
                        id="matrixOptionsDD" 
                        aria-haspopup="true" 
                        aria-expanded="false" 
                        style="cursor: pointer;"
                        :disabled="props.TooManyIDs"
                    >
                        <i class="bi bi-gear-fill" aria-hidden="true"></i> {{ $t('Options') }}
                    </button>
                    <div class="dropdown-menu" aria-labelledby="matrixSizeDD" style="width:275px;">
                        <!-- Frequency Options -->
                        <h1 class="dropdown-header">{{ $t('Frequency Label') }}</h1>
                        <div class="form-check ms-2" v-for="(btn) in frequencyOptionButtons" :key="btn.id">
                            <input 
                                class="form-check-input" 
                                type="checkbox" 
                                :value="btn.value" 
                                v-model="btn.variable" 
                                @change="(e: Event) => { setCheckboxEmits(e) }" 
                                :id="btn.id"
                            >
                            <label class="form-check-label" :title="btn.name" :for="btn.id">
                                {{ btn.name }}
                            </label>
                        </div>

                        <div class="dropdown-divider" />
                        
                        <!-- Consequence option(s) -->
                        <h1 class="dropdown-header">{{ $t('Consequence Label') }}</h1>
                        <div class="form-check ms-2">
                            <input 
                                class="form-check-input" 
                                type="checkbox" 
                                value="ShowConsequenceText" 
                                v-model="showConsequenceText" 
                                @change="(e: Event) => { setCheckboxEmits(e) }" 
                                id="showConsequenceText"
                            >
                            <label class="form-check-label" :title="$t('Show consequence text')" for="showConsequenceText">
                                {{ $t('Show consequence text') }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref, reactive } from 'vue';
    import { $t } from 'o365-utils';
    import { alert } from 'o365-vue-services';
    import type { MatrixSize, FrequencyOptionButton } from 'rams.libraries.vue.components.matrix.MatrixInterfaces.ts';

    const showFrequencyValue = ref<boolean>(true);
    const showFrequencyText = ref<boolean>(false);
    const showFrequencyPercent = ref<boolean>(false);
    const showConsequenceText = ref<boolean>(false);

    const frequencyOptionButtons = reactive<FrequencyOptionButton[]>([
        {
            value: "ShowFrequencyValue",
            variable: showFrequencyValue,
            id: "showFrequencyValueBtn",
            name: $t('Show frequency value')
        },
        {
            value: "ShowFrequencyText",
            variable: showFrequencyText,
            id: "showFrequencyTextBtn",
            name: $t('Show frequency text')
        },
        {
            value: "ShowFrequencyPercent",
            variable: showFrequencyPercent,
            id: "showFrequencyPercentBtn",
            name: $t('Show frequency percent range')
        }
    ])

    const emits = defineEmits<{
        (event: 'MatrixSize', payload: MatrixSize): void;
        (event: 'ShowFrequencyValue', payload: boolean): void;
        (event: 'ShowFrequencyText', payload: boolean): void;
        (event: 'ShowFrequencyPercent', payload: boolean): void;
        (event: 'ShowConsequenceText', payload: boolean): void;
    }>();

    const props = defineProps({
        "TooManyIDs": { type: Boolean, default: false }
    });

    const emitAndSetDropdownElementActive = (e: Event) => {
        const target = e.target as HTMLButtonElement;
        const size = target.getAttribute('msize') as string;
        let mClass: string = '';

        switch(size) {
            case 'small':
                mClass = 'table-matrix-sm';
                break;
            case 'medium':
                mClass = 'table-matrix-md';
                break;
            case 'large':
                mClass = 'table-matrix-lg';
                break;
            default:
                mClass = 'table-matrix-md';
                break;
        }

        const matrixSizePayload: MatrixSize = { size: size ? size : 'medium', class: mClass };
        emits('MatrixSize', matrixSizePayload);

        const activeElement = document.getElementsByClassName('matrix-size active')[0] as HTMLButtonElement;

        if (activeElement) {
            activeElement.classList.remove('active');
        }

        target.classList.add('active');
    }

    const setCheckboxEmits = (element: Event) => {
        const el = element.target as HTMLInputElement;
        const emitName = el.getAttribute('value') as string;
        const isChecked = el.checked as boolean;

        switch (emitName) {
            case 'ShowFrequencyValue':
                emits("ShowFrequencyValue", isChecked);
                break;
            case 'ShowFrequencyText':
                emits("ShowFrequencyText", isChecked);
                break;
            case 'ShowFrequencyPercent':
                emits("ShowFrequencyPercent", isChecked);
                break;
            case 'ShowConsequenceText':
                emits("ShowConsequenceText", isChecked);
                break;
            default:
                alert($t('Something went wrong with setting option. Please try again'), 'danger', { autohide: true, delay: 3000 });
        }
    }
</script>